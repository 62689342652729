import { registerValidation } from '@/utils/valitation/auth';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useCallback, useContext, useState } from 'react';

import toast from 'react-hot-toast';
import {
  register,
  registerByMobile,
  registerVerifyOtpByMobile,
} from '@/services/auth';
import { getCookie, setCookie } from 'cookies-next';
import { event_otp_verification, event_sign_up } from '@/utils/gtm';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import MobileOtpRegister from './MobileOtpRegister';
import { decodeJwt } from '@/utils/global';
import AWSPersonalizeCtx from '@/context/aws-personalize/aws-personalize-context';
import UseLocale from '@/hooks/useLocale';
import CountriesList from './countries-list';
import { countries } from '@/constants/countries';
import TurnstileRecaptcha from '@/components/turnstile';
import { useTurnstile } from 'react-turnstile';

const Register = ({ t, setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [showSubmitOtp, setShowSubmitOtp] = useState(false); // put it true when register with phone to show otp form
  const [formData, setFormData] = useState({}); // local state to save the registration form data to save the data in case using the phone registration
  const turnstile = useTurnstile()
  const [token, setToken] = useState(null)
  const { locale: lang, push, asPath } = useRouter();
  const { locale, store } = UseLocale(lang);
  const [country, setCountry] = useState(
    store === 'ksa' ? countries.gcc.sa : countries.ae
  );

  const [mail, setMail] = useState(country.country_id !== 'SA');
  const handshake = getCookie('handshake');
  const { session } = useSession();
  const { firstActionAfterLoginHandler } = useContext(AWSPersonalizeCtx);

  const intialValuesRegister = {
    firstname: '',
    lastname: '',
    email: '',
    terms: '',
    mobile: '',
    token:'',
    recaptcha: process.env.NEXT_PUBLIC_NODE_ENV === 'local',
  };

  const pushToCheckoutHandler = useCallback(() => {
    asPath === '/cart' ? push('/checkout') : undefined;
  }, [asPath, push]);

  const handleRegister = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    console.log('step 1', values);
    // setSubmitting(true);
    const submittedValues = {
      ...values,
      mobile: '00'
        .concat(country?.country_code)
        ?.concat(values.mobile?.slice(-(country?.placeholder?.length - 1))),
      password: 'zLwtLEmroMUo',
      registerType: !mail ? 'mobile' : 'email',
      token
    };
    console.log('step 2', submittedValues);

    const { data, error } = await register(handshake, submittedValues);
    console.log('step 3', data, error);
    console.log('error', { error, data });
    if (data?.data?.success === 'false' || error) {
      console.log('step 4 on error');
      turnstile?.reset();
      setFieldValue('recaptcha', false);
      setFieldValue('token', '');
      const data = {
        method: !mail ? 'Mobile' : 'Email',
        result: `Signup Failed: ${error?.error?.message}`,
        user_id: null,
      };

      const { method, result, user_id } = data;
      event_sign_up(method, result, user_id);
      // handling the error coming from backend and show to client
      console.log('error register --------------->>', error);
      toast.error(
        data?.data?.errormsg ||
          error?.errors?.message ||
          error?.error?.message ||
          t('Resister Failed')
      );
      setShowSubmitOtp(false);
      return setSubmitting(false);
    } else {
      const dataInfo = {
        method: !mail ? 'Mobile' : 'Email',
        result: `Signup Success`,
        user_id: null,
      };
      const { method, result, user_id } = dataInfo;

      event_sign_up(
        method,
        result,
        user_id,
        submittedValues.email,
        `00${country.country_code}${submittedValues.mobile?.slice(-9)}`,
        submittedValues.firstname,
        submittedValues.lastname
      );

      // check we have token to login
      if (data?.data?.token) {
        const mage = data?.data?.token;
        const userData = data?.data?.user;
        userData?.addresses && delete userData?.addresses;

        await signIn('credentials', {
          user: JSON.stringify({
            ...userData,
            token: mage,
            register_type: data?.data?.register_type,
          }),
          token: mage,
          callbackUrl: '/',
          redirect: false,
        });

        firstActionAfterLoginHandler();
        const decodeMage = decodeJwt(mage);
        setCookie('mage', mage, {
          expires: new Date(decodeMage?.exp * 1000),
        });
        setCookie('email', null);
        setCookie('mobilenumber', null);
        pushToCheckoutHandler();
      }

      toast.success(t('Resister success'));
      resetForm();
      setFormData({});
      setAuth(null);
      return setSubmitting(false);
    }
  };

  const handleRegisterByMobile = async (values, {setSubmitting, resetForm, setFieldValue }) => {
    const mobile = '00'.concat(
      country.country_code.concat(values.mobile?.slice(-9))
    );
    setSubmitting(true);

    const { data, error } = await registerByMobile(
      handshake,
      mobile,
      values?.email,
      values.token
    );

    if (data?.data?.success === 'false' || error) {
      turnstile?.reset();
       setFieldValue('recaptcha', false);
       setFieldValue('token', '');
      const info = {
        method: 'Mobile',
        result: `Signup Failed: ${locale == 'en' ? 'This mobile number is already registered.' : 'تم تسجيل رقم الهاتف المحمول هذا بالفعل.'}`,
        user_id: '',
      };
      const { method, result, user_id } = info;

      event_sign_up(method, result, user_id);
      // handling the error coming from backend and show to client
      error?.message && toast.error(error?.message);
      if (
        error?.error?.startsWith(
          'There is already an account registered with this email'
        )
      ) {
        toast.error(
          locale == 'en'
            ? 'This email address is already registered.'
            : 'تم التسجيل بهذا البريد من قبل'
          // data?.data?.errormsg || error?.error || t('Resister Failed')
        );
      } else console.log('error____', error.error, error);
      toast.error(
        (error.error || '')?.trim() === 'Message Send Failed'
          ? locale === 'ar'
            ? 'فشل إرسال الرسالة'
            : 'Message Send Failed'
          : error.error || locale === 'ar'
            ? 'حدث خطأ ما، يرجى المحاولة مرة أخرى'
            : 'Something went wrong, please try again'
      );
      setSubmitting(false);
    } else {
      const data = {
        method: !mail ? 'Mobile' : 'Email',
        result: `result: ${t('Regestered successfully')}`,
        user_id: session?.user?.id,
      };
      setFormData(values);

      console.log('data in Register', data);
      const { method, result, user_id } = data;

      event_sign_up(method, result, user_id);
      toast.success(
        `${
          locale == 'en'
            ? `OTP has been sent on your ${country?.country_id === 'SA' ? 'mobile number' : 'email'}`
            : `تم إرسال رمز التحقق على ${country?.country_id === 'ksa' ? 'رقم هاتفك المحمول' : 'بريدك الإلكتروني'}`
        }: ${
          country?.country_id === 'SA'
            ? `00${country?.country_code}${values.mobile?.slice(-9)}`
            : values.email
        }`
      );
      resetForm();
      setShowSubmitOtp(true);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const handleOtp = async ({ otp, token }) => {
    setLoading(true);
    const { mobile, email } = formData;
    const { error, data } = await registerVerifyOtpByMobile(
      handshake,
      '00'.concat(country?.country_code)?.concat(mobile?.slice(-9)),
      email,
      otp,
      token
    );
    console.log('error verify otp', error, data)
    if (!error || error == 'null' || error == null) {
      const result = `Verification Success`;
      console.log('error verify otp 2 ', error, data)

      await handleRegister(formData, { resetForm: () => {} });
      event_otp_verification(result);
    } else {
      turnstile?.reset();
      toast.error(locale == 'en' ? 'Invalid OTP' : 'رمز التحقق غير صالح');
    }
    setLoading(false);
  };

  return (
    <>
      {!showSubmitOtp ? (
        <>
          <div className='my-4 text-sm text-center sm:text-lg'>
            <span className='font-bold'>{t('If you have an account,')} </span>
            <span className='text-zinc-500'>
              {t('Log in using your email.')}
            </span>
          </div>

          {false && (
            <div className='grid grid-cols-2 mt-2.5 mb-4 text-sm text-center sm:text-lg'>
              <div
                onClick={() => setMail(true)}
                className='pb-3 text-sm border-b cursor-pointer sm:text-lg'
              >
                <span
                  className={`${mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
                >
                  {t('Login with email')}
                </span>
              </div>
              <div
                onClick={() => setMail(false)}
                className='pb-3 text-sm border-b cursor-pointer sm:text-lg'
              >
                <span
                  className={`${!mail ? 'border-b border-b-[#005471] font-bold' : ''} pb-2`}
                >
                  {t('Login with mobile')}
                </span>
              </div>
            </div>
          )}

          <Formik
            onSubmit={mail ? handleRegister : handleRegisterByMobile}
            initialValues={intialValuesRegister}
            validationSchema={registerValidation(t, country, locale)}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form className='grid grid-cols-2 gap-3 mt-2.5 mb-2'>
                <div className='*:mb-1 max-sm:col-span-full'>
                  <Field
                    name='firstname'
                    className='p-1.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm sm:text-lg'
                    placeholder={t('First Name')}
                  />
                  <ErrorMessage
                    name='firstname'
                    component='p'
                    className='text-sm text-red-600 sm:text-lg'
                  />
                </div>
                <div className='*:mb-1 max-sm:col-span-full'>
                  <Field
                    name='lastname'
                    className='p-1.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm sm:text-lg'
                    placeholder={t('Last Name')}
                  />
                  <ErrorMessage
                    name='lastname'
                    component='p'
                    className='text-sm text-red-600 sm:text-lg'
                  />
                </div>
                <div className='col-span-full *:mb-1'>
                  <Field
                    name='email'
                    type='email'
                    className='p-1.5 w-full rounded-sm border outline-none max-sm:placeholder:text-sm sm:text-lg'
                    placeholder={t('Email Address')}
                  />
                  <ErrorMessage
                    name='email'
                    component='p'
                    className='text-sm text-red-600 sm:text-lg'
                  />
                </div>
                <div className={`col-span-full  *:mb-1`}>
                  <div
                    className={`flex border ${locale.includes('ar') ? 'flex-row' : 'flex-row-reverse'}`}
                  >
                    <Field
                      className='flex-grow p-1.5 rounded-sm outline-none max-sm:placeholder:text-sm sm:text-lg'
                      name='mobile'
                      placeholder={country.placeholder || '05xxxxxxxx'}
                      type='tel'
                      id='mobile'
                    />
                    <CountriesList
                      store={store}
                      countries={countries}
                      onClick={() => console.log('clicked')}
                      {...{ country, setCountry }}
                    />
                  </div>
                  <ErrorMessage
                    dir='auto'
                    name='mobile'
                    component='p'
                    className='text-sm text-red-600 sm:text-lg'
                  />
                </div>
                <TurnstileRecaptcha 
                  action='register'
                  className='flex items-center justify-center p-2 col-span-full'
                  onVerify={(value) => {
                    setFieldValue('token', value)
                    setFieldValue('recaptcha', true)
                  }}/>
                <div className='flex flex-wrap items-center gap-1 col-span-full'>
                  <Field
                    name='terms'
                    type='checkbox'
                    id='accept-terms'
                    className='border rounded-sm appearance-none size-4 checked:border-black checked:bg-black'
                  />
                  <label
                    htmlFor='accept-terms'
                    className='flex gap-1 text-sm text-zinc-600 sm:text-lg'
                  >
                    <span>{t('I Accept')}</span>
                    <span className='font-bold underline'>
                      {t('Terms & Conditions')}
                    </span>
                  </label>
                  <ErrorMessage
                    name='terms'
                    component='p'
                    className='w-full text-sm text-red-600 sm:text-lg'
                  />
                </div>

                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='flex col-span-full gap-2 justify-center items-center p-1.5 py-2 w-full text-sm font-bold text-white bg-black rounded-sm disabled:cursor-not-allowed disabled:opacity-75 max-sm:text-sm sm:text-lg'
                >
                  {isSubmitting && (
                    <span className='border-t border-r border-white rounded-full animate-spin size-4' />
                  )}

                  <span>{t('Register')}</span>
                </button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <MobileOtpRegister handleOtp={handleOtp} t={t} setToken={setToken} loading={loading} />
      )}
    </>
  );
};

export default Register;
